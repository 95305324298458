<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="6"
      >
        <h2>PAGAMENTOS DO {{ String(model.promotor.nome).toUpperCase() }} ({{ promotorId }})</h2>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-row>
          <v-col
            sm="12"
            md="6"
            offset-md="6"
          >
            <v-btn
              color="primary"
              :to="`/promotor/${promotorId}/revendedores`"
              block
            >
              REVENDEDORES DO PROMOTOR
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <datagrid-pagamentos
          :pagamentos="model.pagamentos"
          :show-pagamento="true"
          @applyFilters="loadData"
          @openPagamento="openPagamento"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="model.showPagamentoModal"
      max-width="360px"
      persistent
    >
      <v-card>
        <v-card-title>
          <h3>Tem certeza que deseja realizar o pagamento?</h3>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="model.obs"
            counter
            label="Observações"
            :rules="[v => v.length <= 255 || 'Máximo 255 dígitos.']"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            @click="fecharPagamento"
          >
            Não
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="quitarPagamento"
          >
            Sim
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import DatagridPagamentos from '../components/DatagridPagamentos'

  export default {
    components: {
      DatagridPagamentos,
    },

    props: {
      promotorId: {
        type: String,
        default: () => { return '' },
      },
    },

    data: () => ({
      model: {
        promotor: { nome: 'Promotor' },
        pagamentos: [],
        editedItem: {},
        showPagamentoModal: false,
        obs: '',
      },
    }),

    computed: {
      ...mapGetters(['user']),
    },

    mounted () {
      this.cleanUpModel()
    },

    methods: {
      ...mapActions(['promotor/find']),

      cleanUpModel () {
        this.model.promotor = { nome: 'Promotor' }
        this.model.pagamentos = []
        this.model.editedItem = {}
        this.model.showPagamentoModal = false
        this.model.obs = ''

        this.loadData({})
      },

      loadData (params) {
        params.promotorId = this.promotorId

        Promise.all([
          this.$http.get('promotor/pagamento', { params }),
          this['promotor/find'](Number(this.promotorId)),
        ])
          .then(response => {
            this.model.pagamentos = response[0].data
            this.model.promotor = response[1]
          })
      },

      openPagamento (item) {
        this.model.editedItem = Object.assign({}, item)
        this.model.obs = ''
        this.model.showPagamentoModal = true
      },

      fecharPagamento () {
        this.model.editedItem = {}
        this.model.obs = ''
        this.model.showPagamentoModal = false
      },

      quitarPagamento () {
        const { id } = this.model.editedItem
        this.$http.put(`promotor/pagamento/${id}`, { obs: this.model.obs })
          .then(({ data }) => {
            this.$toast.success('Pagamento gerado com sucesso!')
          })
          .then(this.cleanUpModel)
      },
    },
  }
</script>
